import JwtServices from "../../../services/jwt.services";
import moment from "moment";
import {
  GET_PROVINCE,
  GET_CITY,
  GET_DISTRICT
} from "../../../store/location.module";

import UserTopNav from "../../../components/UserTopNav.vue";

export default {
  components: { UserTopNav },
  data: () => ({
    baseURL: process.env.VUE_APP_API_URL,
    // DATE PICKER
    startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    date_picker_1: false,
    date_picker_2: false,
    defaultDate: true,
    defaultText1: "Start Date",
    defaultText2: "End Date",

    // ticket table & filter
    limit: 10,
    page: 1,
    sort: "desc",

    selected_province: 0,
    selected_city: 0,
    selected_district: 0,

    city: [],
    province: [],
    district: [],

    searchProvince: "",
    searchCity: "",
    searchDistrict: "",

    user: null,

    loading_overlay: false,
    loading_table: false,
    loading_comment: false,

    pagination: {
      page: 1,
      rowsPerPage: 10,
      totalItems: 0,
      rowsPerPageItems: [10, 50, 100, 250, 500]
    },
    headers: [
      {
        text: "Tanggal Pengetesan",
        align: "start",
        sortable: false,
        value: "test_date"
      },
      { text: "Provinsi", value: "province.name", sortable: false },
      { text: "Kota/Kab.", value: "city.name", sortable: false },
      { text: "Kecamatan", value: "district.name", sortable: false },
      { text: "POI", value: "poi", sortable: false },
      { text: "Location", value: "location", sortable: false },
      { text: "Event", value: "event", sortable: false },
      { text: "Test Type", value: "test_type", sortable: false },
      {
        text: "Test Result Data",
        value: "test_result_data",
        sortable: false
      }
    ],

    qosFields: {
      "TANGGAL PENGETESAN": "test_date",
      PROVINSI: "province.name",
      "KAB/KOTA": "city.name",
      KECAMATAN: "district.name",
      POI: "poi",
      LOCATION: "location",
      EVENT: "event",
      "TEST TYPE": "test_type",
      "TEST RESULT DATA": "test_result_data"
    },

    payload: {
      start_date: "",
      end_date: "",
      province_id: 0,
      city_id: 0,
      district_id: 0,

      sub_district_id: 0,
      poi: "",
      event: "",

      limit: 10,
      page: 1,
      sort: "desc"
    },

    selected_item: null,
    dialog_detail: false,

    qosData: [],
    isDownload: false
  }),
  filters: {
    moment: function(date) {
      return moment(date).format("DD/MM/YYYY - HH:mm:ss");
    }
  },
  created() {
    this.user = JwtServices.getUser();

    this.initialize();
    this.getProvince();
  },

  computed: {
    isValid() {
      return this.comment_text.trim() !== "" || this.attachmentFile !== null;
    },

    provinces: function() {
      return this.$store.getters.item_province;
    },

    cities: function() {
      return this.$store.getters.item_city;
    },

    districts: function() {
      return this.$store.getters.item_district;
    },

    province_selected: function() {
      return this.$store.getters.selected_province;
    }
  },

  methods: {
    initialize() {
      this.payload.page = this.pagination.page;
      this.payload.limit = this.pagination.rowsPerPage;
      this.getQosData(this.payload);
    },

    getProvince() {
      this.$store.dispatch(GET_PROVINCE);
    },

    selectCity() {
      if (this.selected_province == this.province_selected) {
        this.$store.dispatch(GET_CITY, this.selected_province);
      } else {
        this.selected_city = 0;
        this.selected_district = 0;
        this.$store.dispatch(GET_CITY, this.selected_province);
      }
    },

    selectDistrict() {
      this.$store.dispatch(GET_DISTRICT, this.selected_city);
    },

    filterDataTable() {
      this.payload.province_id = this.selected_province;
      this.payload.city_id = this.selected_city;
      this.payload.district_id = this.selected_district;
      this.payload.limit = this.pagination.rowsPerPage;

      if (this.defaultDate) {
        this.$swal({
          title: "Date range cannot be blank or empty",
          confirmButtonColor: "#134889",
          icon: "info",
          showConfirmButton: true,
          timer: 3000
        });
        return;
      } else {
        this.payload.start_date = this.startDate;
        this.payload.end_date = this.endDate;
      }

      this.payload.page = 1;

      this.getQosData(this.payload);
    },

    resetFilterDataTable() {
      this.defaultDate = true;
      this.payload.province_id = this.selected_province = 0;
      this.payload.city_id = this.selected_city = 0;
      this.payload.district_id = this.selected_district = 0;
      this.payload.start_date = this.startDate = "";
      this.payload.end_date = this.endDate = "";
      this.payload.limit = this.pagination.rowsPerPage = 10;
      this.payload.page = 1;

      this.getQosData(this.payload);
    },

    async getQosData(payload) {
      this.loading_table = true;
      try {
        const url = new URL(
          process.env.VUE_APP_API_URL + `api/v1/id/qos/network-operator`
        );

        const response = await fetch(url, {
          method: "POST",
          body: JSON.stringify(payload),
          headers: {
            Authorization: `Bearer ${this.user.token}`,
            "Content-Type": "application/json"
          }
        });

        if (response.status === 401) {
          this.logout();
          return;
        }

        const result = await response.json();
        if (result.error) {
          this.$swal({
            text: result.message,
            showConfirmButton: false,
            timer: 3000,
            icon: "error"
          });
          this.qosData = [];
          this.pagination.page = 1;
          this.pagination.totalItems = 1;
        } else {
          this.qosData = result.data.data;

          this.pagination.page = result.data.pagination.current_page;
          this.pagination.totalItems = result.data.pagination.total_page;
        }
      } catch (error) {
        console.log(error);
        this.$swal({
          text: "Terjadi Kesalahan",
          showConfirmButton: false,
          timer: 3000,
          icon: "error"
        });
      } finally {
        this.loading_table = false;
      }
    },

    viewDetailDialog(item) {
      this.dialog_detail = true;
      this.selected_item = item;
    },

    changeFormateDate(dateData) {
      let date = new Date(dateData);
      const formattedDate = date.toLocaleDateString("id-ID", {
        day: "numeric",
        month: "long",
        year: "numeric"
      });

      // Format waktu
      const formattedTime = date.toLocaleTimeString("id-ID", {
        hour: "2-digit",
        minute: "2-digit",
        timeZoneName: "short"
      });

      // Gabungkan hasil tanggal dan waktu
      return `${formattedDate}, ${formattedTime}`;
    },

    async downloadExcel() {
      if (this.defaultDate) {
        this.$swal({
          title: "Date range cannot be blank or empty",
          confirmButtonColor: "#134889",
          icon: "info",
          showConfirmButton: true,
          timer: 3000
        });
        return;
      }
      this.isDownload = true;

      let payload = {
        province_id: this.selected_province,
        city_id: this.selected_city,
        district_id: this.selected_district,
        limit: -1,
        page: 1,
        sort: "desc",
        start_date: this.startDate,
        end_date: this.endDate
      };
      try {
        const url = new URL(
          process.env.VUE_APP_API_URL + `api/v1/id/qos/network-operator`
        );

        const response = await fetch(url, {
          method: "POST",
          body: JSON.stringify(payload),
          headers: {
            Authorization: `Bearer ${this.user.token}`,
            "Content-Type": "application/json"
          }
        });

        if (response.status === 401) {
          this.logout();
          return;
        }

        const result = await response.json();
        if (result.error) {
          this.$swal({
            text: result.message,
            showConfirmButton: false,
            timer: 3000,
            icon: "error"
          });
        } else {
          let data = result.data.data;

          return data;
        }
      } catch (error) {
        console.log(error);
        this.$swal({
          text: "Terjadi Kesalahan",
          showConfirmButton: false,
          timer: 3000,
          icon: "error"
        });
      } finally {
        this.isDownload = false;
      }
    }
  }
};
