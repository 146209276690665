import JwtServices from "../../services/jwt.services";
import moment from "moment";

export default {
  data: () => ({
    baseURL: process.env.VUE_APP_API_URL,
    // DATE PICKER
    startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    date_picker_1: false,
    date_picker_2: false,
    defaultDate: true,
    defaultText1: "Start Date",
    defaultText2: "End Date",
    selectedTicketID: null,
    // ticket table & filter
    limit: 10,
    page: 1,
    sort: "desc",
    cell_operator_type: 0,
    network_operator_id: null,
    selected_source: 0,
    selected_status: 0,
    search: "",

    tab: null,
    dialog_singleComment: false,
    dialog_bulkComment: false,
    singleTicketData: {},

    user: null,
    copyNotification: false,

    loading_overlay: false,
    loading_table: true,
    loading_comment: false,

    pagination: {
      page: 1,
      rowsPerPage: 10,
      totalItems: 0,
      rowsPerPageItems: [10, 50, 100, 250, 500],
    },
    headers: [
      {
        text: "No.Ticket",
        align: "start",
        sortable: false,
        value: "ticket_number",
      },
      { text: "Date", value: "created_at", sortable: false },
      { text: "Subject", value: "subject", sortable: false },
      { text: "Source", value: "app.name", sortable: false },
      {
        text: "Scope",
        value: "source_data[0].app_table.name",
        sortable: false,
      },
      // {
      //   text: "Assigned to",
      //   value: "cell_operator.name",
      //   sortable: false,
      // },
      {
        text: "Signal Strength",
        value: "source_data[0].signal_strength",
        sortable: false,
      },
      { text: "Category", value: "category", sortable: false },
      { text: "Status", value: "ticket_status", sortable: false },
      {
        text: "Latitude",
        value: "source_data[0].user_information.full_address.latitude",
        sortable: false,
      },
      {
        text: "Longitude",
        value: "source_data[0].user_information.full_address.longitude",
        sortable: false,
      },
      { text: "Actions", value: "actions", sortable: false },
    ],

    status: [
      {
        id: 1,
        name: "Open",
      },
      {
        id: 2,
        name: "On Progress",
      },
      {
        id: 3,
        name: "Closed",
      },
    ],

    urgency: [
      {
        value: "rendah",
        name: "Low",
      },
      {
        value: "sedang",
        name: "Medium",
      },
      {
        value: "tinggi",
        name: "High",
      },
    ],

    payload: {
      limit: 10,
      page: 1,
      sort: "desc",
      start_date: "",
      end_date: "",
      app_id: 0,
      ticket_status_id: 0,
      province_id: 0,
      city_id: 0,
      district_id: 0,
      sub_district_id: 0,
      search: "",
    },

    source: [],

    tableData: [],

    noCommentData: false,

    selected_ticket: [],
    ticketsForBulk: [],
    commentList: [],

    comment_text: "",
    attachmentFile: null,
    typeIds: [],
  }),
  filters: {
    moment: function (date) {
      return moment(date).format("DD/MM/YYYY - HH:mm:ss");
    },
  },
  created() {
    this.user = JwtServices.getUser();

    this.getSourceList();
    this.initialize();
  },

  computed: {
    isValid() {
      return this.comment_text.trim() !== "" || this.attachmentFile !== null;
    },
  },

  methods: {
    initialize() {
      this.payload.page = this.pagination.page;
      this.payload.limit = this.pagination.rowsPerPage;

      this.getTicketingDataTable(this.payload);
    },

    changeFormateDate(dateData) {
      let date = new Date(dateData);
      const formattedDate = date.toLocaleDateString("id-ID", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });

      // Format waktu
      const formattedTime = date.toLocaleTimeString("id-ID", {
        hour: "2-digit",
        minute: "2-digit",
        timeZoneName: "short",
      });

      // Gabungkan hasil tanggal dan waktu
      return `${formattedDate}, ${formattedTime}`;
    },

    styleForTicketStatus(ticketStatus) {
      const styles = {
        Open: "pa-3 orange darken-1 white--text font-weight-medium",
        "On Progress": "pa-3 green darken-1 white--text font-weight-medium",
        Closed: "pa-3 blue darken-1 white--text font-weight-medium",
      };

      return styles[ticketStatus] || "";
    },

    filterDataTable() {
      this.payload.app_id = this.selected_source;
      this.payload.ticket_status_id = this.selected_status;
      this.payload.search = this.search;
      this.payload.limit = this.pagination.rowsPerPage;

      if (this.defaultDate) {
        this.payload.start_date = "";
        this.payload.end_date = "";
      } else {
        this.payload.start_date = this.startDate;
        this.payload.end_date = this.endDate;
      }
      this.payload.page = 1;

      this.getTicketingDataTable(this.payload);
    },

    resetFilterDataTable() {
      this.defaultDate = true;
      this.payload.app_id = this.selected_source = 0;
      this.payload.ticket_status_id = this.selected_status = 0;
      this.payload.start_date = this.startDate = "";
      this.payload.end_date = this.endDate = "";
      this.payload.search = this.search = "";
      this.payload.limit = this.pagination.rowsPerPage = 10;
      this.payload.page = 1;

      this.getTicketingDataTable(this.payload);
    },

    async getTicketingDataTable(payload) {
      this.loading_table = true;
      try {
        const url = new URL(
          process.env.VUE_APP_API_URL + `api/v1/id/ticket/network-operator`
        );

        const response = await fetch(url, {
          method: "POST",
          body: JSON.stringify(payload),
          headers: {
            Authorization: `Bearer ${this.user.token}`,
            "Content-Type": "application/json",
          },
        });

        if (response.status === 401) {
          this.logout();
          return;
        }

        const result = await response.json();
        if (result.error) {
          this.$swal({
            text: result.message,
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
          });
          this.tableData = [];
          this.pagination.page = 1;
          this.pagination.totalItems = 1;
        } else {
          this.tableData = result.data.data;
          this.pagination.page = result.data.pagination.current_page;
          this.pagination.totalItems = result.data.pagination.total_page;
        }
      } catch (error) {
        console.log(error);
        this.$swal({
          text: "Terjadi Kesalahan - Data Tabel",
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
        });
      } finally {
        this.loading_table = false;
      }
    },

    async getSourceList() {
      try {
        const url = new URL(
          process.env.VUE_APP_API_URL + `api/v1/id/application`
        );

        const data = {
          limit: 100,
          page: 1,
          sort: "asc",
          start_date: "",
          end_date: "",
          search: "",
        };

        const response = await fetch(url, {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            Authorization: `Bearer ${this.user.token}`,
            "Content-Type": "application/json",
          },
        });

        if (response.status === 401) {
          this.logout();
          return;
        }

        const result = await response.json();
        if (result.error) {
          this.$swal({
            text: result.message,
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
          });
        } else {
          this.source = result.data.data;
        }
      } catch (error) {
        console.log(error);
        this.$swal({
          text: "Terjadi Kesalahan",
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
        });
      } finally {
      }
    },

    openSingleCommentDialog(ticketData) {
      this.typeIds.push(ticketData.id);
      this.selectedTicketID = ticketData.id;
      this.getCommentsForTicket(ticketData.id);
      this.singleTicketData = ticketData;
      this.dialog_singleComment = true;
    },

    resetCommentDialog() {
      this.typeIds = [];
      this.attachmentFile = null;
      this.comment_text = "";
    },

    openBulkCommentDialog() {
      this.ticketsForBulk = this.selected_ticket;
      let tickets = this.ticketsForBulk.map((ticket) => ticket.id);
      this.typeIds = tickets;
      this.dialog_bulkComment = true;
    },

    async getCommentsForTicket(ticketID) {
      this.loading_comment = true;
      try {
        const url = new URL(process.env.VUE_APP_API_URL + `api/v1/id/comments`);

        let params = {
          type: "ticket",
          type_id: ticketID,
        };

        for (let key in params) {
          url.searchParams.append(key, params[key]);
        }

        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${this.user.token}`,
          },
        });

        if (response.status === 401) {
          this.logout();
          return;
        }

        if (response.status === 404) {
          this.noCommentData = true;
          return;
        }

        const result = await response.json();
        if (result.error) {
          this.$swal({
            toast: true,
            position: "top-end",
            text: result.message,
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
          });
        } else {
          // this.$swal({
          //   toast: true,
          //   position: "top-end",
          //   text: result.message,
          //   showConfirmButton: false,
          //   timer: 3000,
          //   icon: "success",
          // });
          this.commentList = result.data;
        }
      } catch (error) {
        console.log(error);
        this.$swal({
          toast: true,
          position: "top-end",
          text: "Terjadi Kesalahan",
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
        });
      } finally {
        this.loading_comment = false;
      }
    },

    async sendTicketComment(typeComment) {
      try {
        const url = new URL(process.env.VUE_APP_API_URL + `api/v1/id/comments`);

        const formData = new FormData();

        formData.append("type", "ticket");
        formData.append("type_ids", this.typeIds.toString());
        formData.append("content", this.comment_text);

        if (this.attachmentFile) {
          formData.append("attachment", this.attachmentFile);
        }

        const response = await fetch(url, {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${this.user.token}`,
          },
        });

        if (response.status === 401) {
          this.logout();
          return;
        }

        const result = await response.json();
        if (result.error) {
          this.$swal({
            toast: true,
            position: "top-end",
            text: result.message,
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
          });
        } else {
          if (typeComment !== "bulk")
            this.getCommentsForTicket(this.selectedTicketID);
          this.$swal({
            toast: true,
            position: "top-end",
            text: result.message,
            showConfirmButton: false,
            timer: 3000,
            icon: "success",
          });
        }
      } catch (error) {
        console.log(error);
        this.$swal({
          toast: true,
          position: "top-end",
          text: "Terjadi Kesalahan",
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
        });
      } finally {
        this.comment_text = "";
        this.attachmentFile = "";
      }
    },

    copyLatlong(item) {
      this.copyNotification = true;
      const lat = item.source_data[0].user_information.full_address.latitude;
      const long = item.source_data[0].user_information.full_address.longitude;
      const latlong = long + ", " + lat;
      navigator.clipboard.writeText(latlong);
    },
  },
};
