import JwtServices from "../../../services/jwt.services";
import VueApexCharts from "vue-apexcharts";
import moment from "moment";
import { AUTH_SIGNOUT } from "../../../store/auth.module";
import UserTopNav from "../../../components/UserTopNav.vue";

export default {
  components: { UserTopNav, apexchart: VueApexCharts },
  data: () => ({
    loadingUploadFile: false,
    loadingUploadAlarm: false,
    dateMenu: false,
    formUpdate: {
      site_id: "",
      site_name: "",
      technology: "",
      category: "",
      severity: "",
      start_at: "",
      description: "",
    },
    dialogUpdate: false,
    uploadAlarmDialog: false,
    disableSendFileButton: true,
    data_alarm_resolved: [],
    uploadAlarmFile: [],
    selectedTab: "update",
    selectedAlarm: null,
    loadingOverlay: true,
    chartSeverities: [
      { title: "Critical", value: "critical" },
      { title: "Major", value: "major" },
      { title: "Minor", value: "minor" },
    ],
    loading: true,
    data_chart: [3, 0],
    data_chart_status: {},
    total_summary_data: [],
    selected_chart: "critical",
    data_chart_range: {},
    date_picker_alarm_1: false,
    date_picker_alarm_2: false,
    dateAlarm: {
      startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      default: true,
      defaultTable: true,
      defaultText: "Select Date",
    },
    startDate: moment().subtract(3, "months").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    date_picker_1: false,
    date_picker_2: false,
    defaultDate: true,
    defaultText: "Select Date",
    user: null,
    network_operator_id: null,
    dialog_confirmation: false,
    dateVal: null,
    timeVal: null,
    pagination: {
      page: 1,
      rowsPerPage: 10,
      totalItems: 0,
      rowsPerPageItems: [10, 50, 100, 250, 500],
    },
    selected_severity: "",
    list_technology: ["2G", "3G", "4G"],
    list_severirty: ["Critical", "Major", "Minor"],
    list_periode: ["30 Hari Terakhir", "7 Hari Terakhir"],
    headers: [
      {
        text: "Created Time",
        value: "created_at",
        sortable: false,
      },
      { text: "Site ID", value: "site_id", sortable: false },
      { text: "Site Name", value: "site_name", sortable: false },
      { text: "Technology", value: "technology", sortable: false },
      { text: "Device", value: "device", sortable: false },
      { text: "Category", value: "category", sortable: false },
      { text: "Severity", value: "severity", sortable: false },
      { text: "Status", value: "status", sortable: false },
      { text: "Start At", value: "start_at", sortable: false },
      { text: "Description", value: "description", sortable: false },
      { text: "Close At", value: "close_at", sortable: false },
      { text: "Action", value: "action", sortable: false },
    ],
    headersResolved: [
      {
        text: "Created Time",
        value: "created_at",
        sortable: false,
      },
      { text: "Site ID", value: "site_id", sortable: false },
      { text: "Site Name", value: "site_name", sortable: false },
      { text: "Technology", value: "technology", sortable: false },
      { text: "Device", value: "device", sortable: false },
      { text: "Category", value: "category", sortable: false },
      { text: "Severity", value: "severity", sortable: false },
      { text: "Status", value: "status", sortable: false },
      { text: "Start At", value: "start_at", sortable: false },
      { text: "Close At", value: "close_at", sortable: false },
      { text: "Description", value: "description", sortable: false },
    ],
    data_alarm: [],
    selected_data_alarm: [],
  }),
  filters: {
    dateFormat: function (date) {
      return moment(date).format("DD/MM/YYYY - HH:mm:ss");
    },
  },
  computed: {
    formattedDateTime() {
      if (!this.formUpdate.start_at) return "";
      return moment(this.formUpdate.start_at).format("DD/MM/YYYY HH:mm:ss");
    },
    currentDate() {
      return moment().format("YYYY-MM-DD");
    },
    threeMonthsDate() {
      return moment().subtract(3, "months").format("YYYY-MM-DD");
    },
    dateDisplay: {
      //convert html standard datetime value into local time
      get() {
        return {
          startDate: moment(this.dateAlarm.startDate)
            .locale("id-ID")
            .format("DD-MM-YYYY"),
          endDate: moment(this.dateAlarm.endDate)
            .locale("id-ID")
            .format("DD-MM-YYYY"),
          threemonthago: moment().subtract(2, "month").format("YYYY-MM-DD"),
        };
      },
    },
  },
  mounted() {
    this.user = JwtServices.getUser();
    this.network_operator_id = this.user.network_operator.id;
    this.initialize();
  },
  watch: {
    uploadAlarmDialog(val) {
      if (!val) {
        // When dialog closes
        this.uploadAlarmFile = [];
        this.disableSendFileButton = true;
        this.loadingUploadFile = false;

        // Reset the file input element
        if (this.$refs.fileInput) {
          this.$refs.fileInput.value = "";
        }

        // Remove any styling classes from drop area
        const dropArea = document.getElementById("dropArea");
        if (dropArea) {
          dropArea.classList.remove("light-blue", "lighten-5");
        }
      }
    },
  },
  methods: {
    initialize() {
      this.getSummaryRange();
      this.getSummaryStatus();
      const payload = {
        limit: 10,
        page: 1,
        startDate: "",
        endDate: "",
      };
      this.getAlarm(payload);
    },
    async updateAlarm() {
      const url = `${process.env.VUE_APP_API_URL}api/v1/${this.user.language}/alarm/${this.formUpdate.id}`;
      const payload = {
        site_id: this.formUpdate.site_id,
        site_name: this.formUpdate.site_name,
        technology: this.formUpdate.technology,
        category: this.formUpdate.category,
        severity: this.formUpdate.severity,
        start_at: moment(this.formUpdate.start_at).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        description: this.formUpdate.description,
      };
      const options = {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${this.user.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      };

      try {
        const response = await fetch(url, options);
        const data = await response.json();
        this.dialogUpdate = false;
        if (data.error) {
          this.$swal({
            text: data.message,
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
          });
        } else {
          this.$swal({
            title: "Success",
            text: data.message,
            timer: 1000,
            showConfirmButton: false,
            icon: "success",
          });
          this.getAlarmByPage();
        }
      } catch (error) {
        console.error(error);
      }
    },
    setEditData(alarmItem) {
      const dateTime = moment(alarmItem.start_at);
      this.dateVal = dateTime.format("YYYY-MM-DD");
      this.timeVal = dateTime.format("HH:mm");

      this.formUpdate = JSON.parse(
        JSON.stringify({
          ...alarmItem,
          start_at: dateTime.format("YYYY-MM-DD HH:mm:ss"),
        })
      );
      this.dialogUpdate = true;
    },
    combineDateTime() {
      if (this.dateVal && this.timeVal) {
        const dateTime = moment(`${this.dateVal} ${this.timeVal}`);
        this.formUpdate.start_at = dateTime.format("YYYY-MM-DD HH:mm:ss");
      }
    },

    saveDateTime() {
      this.combineDateTime();
      this.dateTimeMenu = false;
    },
    openDialogSubmit() {
      this.uploadAlarmDialog = true;
      this.uploadAlarmFile = [];
      this.disableSendFileButton = true;
      this.loadingUploadFile = false;

      // Reset the file input element
      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = "";
      }

      // Remove any styling classes from drop area
      const dropArea = document.getElementById("dropArea");
      if (dropArea) {
        dropArea.classList.remove("light-blue", "lighten-5");
      }
    },
    resetFilter() {
      const payload = {
        limit: 10,
        page: 1,
        startDate: "",
        endDate: "",
      };
      this.selected_severity = "";
      this.getAlarm(payload);
    },
    resetFilterDataChart() {
      this.startDate = this.currentDate;
      this.endDate = this.endDate;
      this.loadingOverlay = true;
      this.getSummaryStatus();
    },
    async getSummaryRange() {
      const url = `${process.env.VUE_APP_API_URL}api/v1/${this.user.language}/alarm/summary/date-range`;

      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.user.token}`,
        },
      };

      try {
        const response = await fetch(url, options);
        const data = await response.json();
        if (data.error) {
          this.$swal({
            text: data.message,
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
          });
        } else {
          this.data_chart_range = data.data;
        }
      } catch (error) {
        this.$swal({
          text: error.message,
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
        });
      } finally {
        this.loadingOverlay = false;
      }
    },

    async getSummaryStatus() {
      this.loadingOverlay = true;
      const url = `${process.env.VUE_APP_API_URL}api/v1/${this.user.language}/alarm/summary/severity-status?start_date=${this.startDate}&end_date=${this.endDate}`;
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.user.token}`,
        },
      };

      try {
        const response = await fetch(url, options);
        const data = await response.json();
        if (data.error) {
          this.$swal({
            text: data.message,
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
          });
        } else {
          const transformedData = {};
          Object.keys(data.data).forEach((key) => {
            transformedData[key] = [
              data.data[key].active_alarm,
              data.data[key].closed_alarm,
            ];
          });
          this.data_chart_status = transformedData;
        }
      } catch (error) {
        this.$swal({
          text: error.message,
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
        });
      } finally {
        this.loadingOverlay = false;
      }
    },
    clearSeverity() {
      this.selected_severity = "";
    },
    inputDate(val) {
      if (val == "table") {
        this.dateAlarm.defaultTable = false;
      } else {
        this.dateAlarm.default = false;
      }
    },
    getAlarmByPage() {
      const payload = {
        limit: this.pagination.rowsPerPage,
        page: this.pagination.page,
        startDate: this.dateAlarm.default ? "" : this.dateAlarm.startDate,
        endDate: this.dateAlarm.default ? "" : this.dateAlarm.endDate,
      };
      this.getAlarm(payload);
    },
    applyFilter() {
      const payload = {
        startDate: this.dateAlarm.default ? "" : this.dateAlarm.startDate,
        endDate: this.dateAlarm.default ? "" : this.dateAlarm.endDate,
        limit: this.pagination.rowsPerPage,
        page: 1,
      };

      if (this.selectedTab === "update") {
        this.getAlarm(payload);
      } else {
        this.getAlarmHistory(payload);
      }
    },
    getAlarmByRow() {
      const payload = {
        limit: this.pagination.rowsPerPage,
        page: 1,
        startDate: this.dateAlarm.default ? "" : this.dateAlarm.startDate,
        endDate: this.dateAlarm.default ? "" : this.dateAlarm.endDate,
      };
      this.getAlarm(payload);
    },
    getAlarm(payload) {
      this.loading_overlay = true;
      this.loading = true;
      let data = {
        limit: payload.limit,
        page: payload.page,
        sort: "desc",
        start_date: payload.startDate,
        end_date: payload.endDate,
        search: "",
        status: "open",
        province_id: "[]",
        city_id: "[]",
        district_id: "[]",
        sub_district_id: "[]",
        cell_operator_id: this.user.network_operator.id,
        severity: this.selected_severity,
      };

      fetch(
        process.env.VUE_APP_API_URL + `api/v2/${this.user.language}/alarm`,
        {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ` + this.user.token,
          },
        }
      )
        .then((response) => {
          response.status === 401 &&
            this.$store.dispatch(AUTH_SIGNOUT, this.user.id);
          return response.json();
        })
        .then((result) => {
          this.loading_overlay = false;
          this.loading = false;
          // Check error
          if (result.error) {
            this.data_alarm = [];
            this.pagination.page = 1;
            this.pagination.totalItems = 1;
            return;
          }

          this.data_alarm = result.data.data;
          this.pagination.page = result.data.pagination.current_page;
          this.pagination.totalItems = result.data.pagination.total_page;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAlarmHistory(payload) {
      this.loading_overlay = true;
      let data = {
        limit: payload.limit,
        page: payload.page,
        sort: "desc",
        start_date: payload.startDate,
        end_date: payload.endDate,
        search: "",
        status: "resolved",
        province_id: "[]",
        city_id: "[]",
        district_id: "[]",
        sub_district_id: "[]",
        cell_operator_id: this.user.network_operator.id,
        severity: this.selected_severity,
      };

      fetch(
        process.env.VUE_APP_API_URL + `api/v2/${this.user.language}/alarm`,
        {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ` + this.user.token,
          },
        }
      )
        .then((response) => response.json())
        .then((result) => {
          this.data_alarm = [];
          this.loading_overlay = false;
          this.loading = false;
          // Check error
          if (result.error) {
            this.data_alarm = [];
            this.pagination.page = 1;
            this.pagination.totalItems = 1;
            this.$swal({
              text: result.message,
              showConfirmButton: false,
              timer: 3000,
              icon: "error",
            });
            return;
          }

          this.data_alarm_resolved = result.data.data;
          this.pagination.page = result.data.pagination.current_page;
          this.pagination.totalItems = result.data.pagination.total_page;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    dragover(event) {
      event.preventDefault();
    },

    dragenter(event) {
      document
        .getElementById("dropArea")
        .classList.add("light-blue", "lighten-5");
    },
    dragleave(event) {
      // Clean up visual fluff
      document
        .getElementById("dropArea")
        .classList.remove("light-blue", "lighten-5");
    },

    drop(event) {
      event.preventDefault();
      this.$refs.fileInput.files = event.dataTransfer.files;
      this.adduploadAlarmFiles(); // Trigger the onChange event manually
    },
    removeuploadAlarmFiles(i) {
      this.uploadAlarmFile.splice(i, 1);
      if (this.uploadAlarmFile.length === 0) {
        this.disableSendFileButton = true;
        document
          .getElementById("dropArea")
          .classList.remove("light-blue", "lighten-5");
      }
    },
    // Download Template file for Bulk Close Ticket
    downloadTemplateuploadAlarmFile() {
      const link = document.createElement("a");
      link.href = "/ALARM.xlsx";
      link.download = "ALARM.xlsx";
      link.click();
    },
    adduploadAlarmFiles() {
      this.uploadAlarmFile = [...this.$refs.fileInput.files];
      this.loadingBulkCloseTicket = true;
      if (this.uploadAlarmFile.length > 0) {
        this.disableSendFileButton = false;
      }
      setTimeout(() => {
        this.loadingBulkCloseTicket = false;
      }, 2000);
    },
    selectUploadAlarmFiles() {
      this.$refs.fileInput.click();
    },
    sendUploadAlarmFile() {
      this.loading_overlay = true;

      let formData = new FormData();
      formData.append("attachment", this.uploadAlarmFile[0]);

      fetch(
        process.env.VUE_APP_API_URL +
          `api/v2/${this.user.language}/upload/alarm`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ` + this.user.token,
          },
        }
      )
        .then((response) => {
          this.response = response;
          return response.json();
        })
        .then((result) => {
          this.loading_overlay = false;
          this.uploadAlarmDialog = false;
          this.resetFilter();
          // Check error
          if (result.error) {
            this.$swal("Opps", result.message, "error");
            return;
          } else {
            this.$swal("Success", result.message, "success");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    resolveAlarm() {
      this.loading_overlay = true;

      let data = {
        alarm_data: JSON.stringify(this.selected_data_alarm),
      };

      fetch(
        process.env.VUE_APP_API_URL +
          `api/v1/${this.user.language}/alarm/resolve`,
        {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ` + this.user.token,
          },
        }
      )
        .then((response) => response.json())
        .then((result) => {
          this.loading_overlay = false;
          if (result.error == true) {
            this.$swal("Opps", result.message, "error");
            return;
          }

          this.dialog_confirmation = false;
          this.$swal({
            title: "Success",
            text: result.message,
            timer: 1000,
            showConfirmButton: false,
            icon: "success",
          });
          this.switchTab("history");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    switchTab(tab) {
      this.selectedTab = tab;
      this.dateAlarm.default = true;
      this.selected_severity = "";
      const payload = {
        limit: 10,
        page: 1,
        startDate: "",
        endDate: "",
      };
      if (tab === "update") {
        this.getAlarm(payload);
      } else {
        this.getAlarmHistory(payload);
      }
    },
  },
};
